<template>
  <div class="g_page_box">
    <div class="g_main_content">
      <g-loading :loading="loading" />
      <div class="type_title">
        患者信息 
      </div>
      <div class="center">
        <van-field v-model="name" label="姓名" :clearable="true" />
        <van-cell title="关系" value="父亲" is-link />
        <van-cell title="生存状态" value="健康" is-link />
        <van-field v-model="death" label="死因" :clearable="true" />
      </div>
      <div class="add_btn">
        添加
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      name: '李星星',
      death: '未知',
    };
  },
  computed: {},
  components: {
    
  },
  watch: {},
  created() {
    
  },
  methods: {
    
  },
};
</script>

<style scoped>
.g_main_content {
  background: #F2F7F8;
}
.type_title {
  padding: 12px 16px;
  font-size: 14px;
  line-height: 18px;
  color: #999999;
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.center {
  background: #fff;
}
>>>.van-cell__title {
  flex: none;
  width: 25%;
  margin-right: 12px;
}

>>>.van-cell__value,
>>>.van-field__label {
  text-align: left;
  color: #323232;
}

.add_btn {
  width: calc(100% - 56px);
  height: 43px;
  background: #0088FF;
  opacity: 1;
  border-radius: 22px;
  position: fixed;
  left: 28px;
  bottom: 28px;
  font-size: 16px;
  font-weight: 500;
  line-height: 43px;
  color: #FFFFFF;
  text-align: center;
}
</style>
